import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './index.css';

import * as serviceWorker from './serviceWorker';
import config from './config';
import utils from './utils/utils';

import App from './pages/App/App';
import Login from './pages/Login/Login';

window.firebase.initializeApp(config.FIREBASE);

const PrivateRoute = ({ component: Component, ...routeProps }) => (
    <Route
        {...routeProps}
        render={props =>
            utils.isUserLoggedIn() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            )
        }
    />
);

const Routes = (
    <Router>
        <TransitionGroup className="routes-transition">
            <CSSTransition key="routes-transition" classNames="fade" timeout={500}>
                <Switch>
                    <Route exact path="/login" component={ Login } />
                    <PrivateRoute exact path="/app" component={ App } />
                    <Redirect from="/" to="/app" />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    </Router>
);

ReactDOM.render(Routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
