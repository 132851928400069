import React from 'react';

import './LoadingSpinner.css';

const loadingSpinnerBars = [];
for (let i = 0; i < 12; i++) {
    loadingSpinnerBars.push(
        <div key={'lds-spinner-bar-' + i} className="lds-spinner__bar"></div>
    );
}

const LoadingSpinner = props => (
    <div className={'lds-spinner' + (props.className ? ' ' + props.className : '')}>
        {loadingSpinnerBars}
        {props.message && <div className="lds-spinner__message">{props.message}</div>}
    </div>
);

export default LoadingSpinner;