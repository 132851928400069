import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';

import config from './../config';
import utils from './../utils/utils';

const mapUtils = {
    routePath: null,
    map: null,
    directionsService: null,
    tourPoints: [], // Tour points fetched from the DB (unparsed)
    routePoints: [],
    markers: [],
    isEditMode: false,
    isAddMarkerMode: false,
    markerOrderNumber: null,
    selectedMarker: null,
    editHistory: [],
    editHistoryIndex: 0,

    initMapUtils: (setTourPoints, showLoading) => {
        mapUtils.setTourPoints = setTourPoints;
        mapUtils.showLoading = showLoading;
    },

    /**
     * Background geo fencing
     */

    initBackgroundLocationGeoFencing: () => {
        if (window.cordova && window.backgroundGeolocation) {
            window.backgroundGeolocation.configure(
                mapUtils.onBackgroundLocationUpdate,
                mapUtils.onBackgroundLocationError,
                {
                    desiredAccuracy: 0,
                    stationaryRadius: 10,
                    distanceFilter: 10,
                    notificationTitle: 'Background tracking',
                    notificationText: 'enabled',
                    interval: 5000,
                    fastestInterval: 5000,
                    activitiesInterval: 5000
                }
            );

            window.backgroundGeolocation.start();
        }
    },

    stopBackgroundLocationGeoFencing: () => {
        if (window.cordova && window.backgroundGeolocation) {
            window.backgroundGeolocation.stop();
        }
    },

    onBackgroundLocationUpdate: location => {
        const point = new window.google.maps.LatLng(location.latitude, location.longitude);
        const isDriverOnRoute = window.google.maps.geometry.poly.isLocationOnEdge(
            point,
            mapUtils.routePath,
            config.ROUTE_DEVIATION_TOLERANCE
        );

        if (!isDriverOnRoute) {
            window.cordova.plugins.notification.local.schedule({
                title: 'You deviated from the route!',
                text: 'Please reopen Google Maps with the original route.'
            })
        }
    },

    onBackgroundLocationError: error => console.error(error),


    /**
     * Google Maps
     */

    initMap: () => {
        mapUtils.map = new window.google.maps.Map(document.getElementById('google-map'), config.GOOGLE_MAPS_SETTINGS);
        mapUtils.map.addListener('click', mapUtils.addNewMarkerToRoute);
        mapUtils.directionsService = new window.google.maps.DirectionsService();
    },

    addRouteMarker: (tourPoint, location, address, distance, duration) => {
        const infoWindow = new window.google.maps.InfoWindow({
          content: `
            <div class="map-tour__info-window">
              <h2>${address}</h2>
                ${distance ? '<h3><strong>' + distance + '</strong> from last point</h3>' : ''}
              <h3>
                ${duration ? 'ETA: <strong>' + duration + '</strong>' : 'ETA: ? ' }
              </h3>
              ${
                  mapUtils.isEditMode ? '<button type="button" class="delete-button">DELETE</button>' : ''
              }
            </div>
          `
        });

        const markerId = tourPoint['ID'].toString();
        const marker = new window.google.maps.Marker({
            position: location,
            label: markerId,
            map: mapUtils.map,
            draggable: mapUtils.isEditMode
        });
        
        marker.addListener('click', () => infoWindow.open(mapUtils.map, marker));
        marker.addListener('dragend', () => mapUtils.editMarkerPosition(marker, markerId));
        infoWindow.addListener('domready', () => {
            mapUtils.selectedMarker = markerId;
            const deleteButton = document.querySelector('.map-tour__info-window > .delete-button');
            if (deleteButton) {
                deleteButton.addEventListener('click', mapUtils.deleteMarker);
            }
        });

        mapUtils.markers.push(marker);
    },

    drawRouteSegment: (points, startPointIndex = 0, endPointIndex = 11, isFirstSegment = true) => {
        const startPoint = points[startPointIndex];
        const endPoint = points[endPointIndex];
        const startPointCoords = startPoint['Latitude'] + ', ' + startPoint['Longitude'];
        const endPointCoords = endPoint['Latitude'] + ', ' + endPoint['Longitude'];
        let waypoints = [];

        mapUtils.showLoading(true, `Loading waypoints ${startPoint['ID']} to ${endPoint['ID']}...`);
    
        for (let i = startPointIndex + 1; i < endPointIndex; i++) {
          waypoints.push({
            location: points[i]['Latitude'] + ', ' + points[i]['Longitude'],
            stopover: true
          });
        }

        return new Promise((resolve, reject) => setTimeout(() => {
            mapUtils.directionsService.route({
                origin: startPointCoords,
                destination: endPointCoords,
                waypoints: waypoints,
                travelMode: 'DRIVING',
                optimizeWaypoints: false
            }, (response, status) => {
                if (status === 'OK') {
                    mapUtils.routePoints = mapUtils.routePoints.concat(
                        (response.routes[0] && response.routes[0].overview_path) || []
                    );
                    const routeLegs = (response.routes[0] && response.routes[0].legs) || [];
                    const waypointsOrder = response.routes[0] && response.routes[0].waypoint_order;
                    
                    for (let i = 0; i < routeLegs.length; i++) {
                        const leg = routeLegs[i];
                        const endLocation = { lat: leg.end_location.lat(), lng: leg.end_location.lng() };

                        mapUtils.totalRouteDistance += leg.distance.value;
                        mapUtils.totalRouteDuration += leg.duration.value;

                        if (i === 0) {

                            // Add the first marker if it's the first route segment,
                            // else it was added when drawing the previous route segment
                            if (isFirstSegment) {
                                const startLocation = { lat: leg.start_location.lat(), lng: leg.start_location.lng() };
                                mapUtils.addRouteMarker(startPoint, startLocation, leg.start_address);
                            }

                            if (startPointIndex !== endPointIndex) {
                                const tourPoint = points[
                                    // Also treats the case when there are no waypoints, just origin and destination
                                    endPointIndex > startPointIndex + 1 ? startPointIndex + waypointsOrder[i] + 1 : endPointIndex
                                ];
                                mapUtils.addRouteMarker(tourPoint, endLocation, leg.end_address, leg.distance.text, leg.duration.text);
                            }
                        } else {
                            const tourPoint = points[
                                startPointIndex + i + 1 === endPointIndex ? endPointIndex : startPointIndex + waypointsOrder[i] + 1
                            ];
                            mapUtils.addRouteMarker(tourPoint, endLocation, leg.end_address, leg.distance.text, leg.duration.text);
                        }
                    }

                    resolve();
                } else {
                    console.error('Status: ', status);
                    console.error(response);

                    if (status === 'ZERO_RESULTS') {
                      return reject('No route could be found between some of the waypoints.');
                    }

                    reject('An error occured while trying to add the route on the map.');
                }
            });
        }, 1000)); // Do not exceed the Google usage limit of 1 request per second
    },

    drawRouteOnMap: async (points, isEditMode = false, startPointIndex = 0, endPointIndex = 11) => {
        mapUtils.totalRouteDistance = 0;
        mapUtils.totalRouteDuration = 0;
        mapUtils.isEditMode = isEditMode;
        mapUtils.routePoints = [];
        mapUtils.tourPoints = points;

        try {
            mapUtils.map.setCenter({
              lat: points[startPointIndex]['Latitude'],
              lng: points[startPointIndex]['Longitude']
            });

            if (endPointIndex - startPointIndex < config.MAX_WAYPOINTS + 2) {
                await mapUtils.drawRouteSegment(points, startPointIndex, endPointIndex);
            } else {
                let routeSegmentStartPointIndex = startPointIndex;
                let routeSegmentEndPointIndex = startPointIndex + config.MAX_WAYPOINTS + 1;
                let isFirstSegment = true;
                let routeFinished = false;

                while (!routeFinished) {
                    await mapUtils.drawRouteSegment(
                        points,
                        routeSegmentStartPointIndex,
                        routeSegmentEndPointIndex,
                        isFirstSegment
                    );
                    
                    isFirstSegment = false;
                    routeSegmentStartPointIndex = routeSegmentEndPointIndex;
                    if (routeSegmentStartPointIndex + config.MAX_WAYPOINTS + 1 > endPointIndex) {
                        routeSegmentEndPointIndex = endPointIndex;
                    } else if (routeSegmentStartPointIndex + config.MAX_WAYPOINTS + 1 >= points.length) {
                        routeSegmentEndPointIndex = points.length - 1;
                    } else {
                        routeSegmentEndPointIndex = routeSegmentStartPointIndex + config.MAX_WAYPOINTS + 1;
                    }

                    if (routeSegmentStartPointIndex === points.length - 1 || routeSegmentStartPointIndex === endPointIndex) {
                        routeFinished = true;
                    }
                }
            }
        } catch (error) {
            alert(error);
        }

        if (!isEditMode) {
            mapUtils.centerMapToFitRoute(mapUtils.routePoints);
        }

        // Clear previous route
        if (mapUtils.routePath) { 
            mapUtils.routePath.setMap(null);
        }
        mapUtils.routePath = new window.google.maps.Polyline({
            path: mapUtils.routePoints,
            geodesic: true,
            strokeColor: '#2675FF',
            strokeOpacity: 0.6,
            strokeWeight: 7
        });
        mapUtils.routePath.setMap(mapUtils.map);

        // Init geofencing so that the driver follows the route, if not disabled in settings
        const settings = utils.getLocalStorageObject('settings');
        if (settings && settings.enableRouteDeviationNotifications) {
            mapUtils.initBackgroundLocationGeoFencing();
        }

        return {
            totalRouteDistance: mapUtils.totalRouteDistance,
            totalRouteDuration: mapUtils.totalRouteDuration
        }
    },

    centerMapToFitRoute: routePoints => {
        const bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < routePoints.length; i++) {
            bounds.extend(routePoints[i]);
        }
        mapUtils.map.fitBounds(bounds);
    },

    clearRoute: () => {

        // Remove markers and their event listeners
        mapUtils.markers.forEach(marker => {
            window.google.maps.event.clearListeners(marker, 'click');
            marker.setMap(null);
        });
        mapUtils.markers = [];

        mapUtils.routePath && mapUtils.routePath.setMap(null); // Clear any drawn routes
        mapUtils.stopBackgroundLocationGeoFencing(); // Stop background geofencing service
    },

    buildGoogleMapsTourUrl: (points, startPointIndex = 0, endPointIndex = 10) => {
        let url = config.OPEN_MAPS_APP_BASE_URL;
        const mapPoints = points || this.state.mapPoints;
        const destination = '&destination=' + mapPoints[endPointIndex]['Latitude'] + ',' + mapPoints[endPointIndex]['Longitude'];
    
        url += destination + '&waypoints=';
        for (let i = startPointIndex; i < endPointIndex; i++) {
          url += mapPoints[i]['Latitude'] + ',' + mapPoints[i]['Longitude'] + (i === endPointIndex - 1 ? '' : '|');
        }
    
        return url;
    },


    /**
     * Tour edit features
     */

    setAddMarkerMode: (mode, markerOrderNumber) => {
        document.getElementById('google-map').classList.toggle('add-marker-mode');
        mapUtils.markerOrderNumber = markerOrderNumber || null;
        mapUtils.isAddMarkerMode = mode;
    },

    getNewTourPoint: async (lat, lng, markerId) => {
        const response = await axios.request({
            url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${config.GOOGLE_MAPS_API_KEY}`,
            method: 'get'
        });

        const approximateLocation = response.data.results[0];
        const newAddress = approximateLocation.formatted_address;
        const newPosition = approximateLocation.geometry.location;

        return {
            'ID': markerId,
            'Latitude': newPosition.lat,
            'Longitude': newPosition.lng,
            'Description': newAddress
        };
    },

    editMarkerPosition: async (marker, markerId) => {
        const latLng = marker.getPosition();
        const newPoints = cloneDeep(mapUtils.tourPoints);

        try {
            const newTourPoint = await mapUtils.getNewTourPoint(latLng.lat(), latLng.lng(), markerId);

            for (let i = 0; i < mapUtils.tourPoints.length; i++) {
                if (mapUtils.tourPoints[i]['ID'].toString() === markerId) {
                    newPoints[i] = newTourPoint;
                    break;
                }
            }

            mapUtils.addToHistory(newPoints);
            mapUtils.setTourPoints(newPoints); // After moving the marker, draw the new route
        } catch (error) {
            alert("Failed to update marker position:\n" + error.message);
        }
    },

    addNewMarkerToRoute: async (e) => {
        if (mapUtils.isAddMarkerMode && mapUtils.markerOrderNumber) {
            const latLng = e.latLng;
            const newPoints = cloneDeep(mapUtils.tourPoints);

            const newTourPoint = await mapUtils.getNewTourPoint(latLng.lat(), latLng.lng(), mapUtils.markerOrderNumber);

            const insertIndex = mapUtils.markerOrderNumber - 1;
            newPoints.splice(insertIndex, 0, newTourPoint);
            for (let i = newPoints.length - 1; i > insertIndex; i--) {
                newPoints[i]['ID'] = (parseInt(newPoints[i]['ID'], 10) + 1).toString();
            }

            mapUtils.addToHistory(newPoints);
            mapUtils.setTourPoints(newPoints);
            mapUtils.setAddMarkerMode(false);
        }
    },

    deleteMarker: () => {
        const markerId = mapUtils.selectedMarker;

        if (markerId) {
            const newPoints = cloneDeep(mapUtils.tourPoints);
            const deleteIndex = newPoints.findIndex(point => point['ID'].toString() === markerId);

            newPoints.splice(deleteIndex, 1);
            for (let i = deleteIndex; i < newPoints.length; i++) {
                newPoints[i]['ID'] = (parseInt(newPoints[i]['ID'], 10) - 1).toString();
            }

            mapUtils.addToHistory(newPoints);
            mapUtils.setTourPoints(newPoints);
        }
    },

    initEditHistory: initialPoints => {
        mapUtils.editHistoryIndex = 0;
        mapUtils.editHistory = [initialPoints];
    },

    clearHistory: () => {
        mapUtils.editHistoryIndex = 0;
        mapUtils.editHistory = [];
    },

    discardTourChanges: () => {
        const initialTourPoints = cloneDeep(mapUtils.editHistory[0]);
        mapUtils.editHistory = [];
        mapUtils.editHistoryIndex = 0;
        mapUtils.setTourPoints(initialTourPoints);
    },

    wereChangesMade: () => mapUtils.editHistory.length > 1,

    addToHistory: entry => {
        mapUtils.clearRedoActions();
        mapUtils.editHistoryIndex++;
        mapUtils.editHistory.push(entry);
    },

    clearRedoActions: () => mapUtils.editHistory = mapUtils.editHistory.slice(0, mapUtils.editHistoryIndex + 1),
    isStartOfHistory: () => mapUtils.editHistoryIndex === 0,
    isEndOfHistory: () => !mapUtils.editHistory.length || mapUtils.editHistoryIndex === mapUtils.editHistory.length - 1,

    undoEdit: () => {
        if (mapUtils.editHistoryIndex > 0) {
            mapUtils.editHistoryIndex--;
            mapUtils.setTourPoints(mapUtils.editHistory[mapUtils.editHistoryIndex]);
        }
    },

    redoEdit: () => {
        if (mapUtils.editHistoryIndex < mapUtils.editHistory.length - 1) {
            mapUtils.editHistoryIndex++;
            mapUtils.setTourPoints(mapUtils.editHistory[mapUtils.editHistoryIndex]);
        }
    }
};

window.initMap = mapUtils.initMap; // Make initMap a global function so that Google Maps script can load it.

export default mapUtils;