import React, { Component } from 'react';
import Modal from 'react-modal';

import config from './../../config';
import utils from './../../utils/utils';
import mapUtils from './../../utils/map-utils';

import './SettingsModal.css';
import closeButtonIcon from './../../assets/close.svg';

class SettingsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: utils.getLocalStorageObject('user'),
            settings: utils.getLocalStorageObject('settings') || {
                enableRouteDeviationNotifications: false
            }
        };
    }

    toggleRouteDeviationNotifications = () => {
        this.setState({
            settings: {
                enableRouteDeviationNotifications: !this.state.settings.enableRouteDeviationNotifications
            }
        });
    }

    signOut = async () => {
        try {
            await window.firebase.auth().signOut();
            window.localStorage.removeItem('user');
            this.props.history.replace('/');
        } catch (error) {
            alert('Could not sign out. Please try again.');
        }
    };

    saveSettings = () => {
        const initialSetings = utils.getLocalStorageObject('settings') || {};
        window.localStorage.setItem('settings', JSON.stringify(this.state.settings));

        if (initialSetings.enableRouteDeviationNotifications !== this.state.settings.enableRouteDeviationNotifications) {
            if (this.state.settings.enableRouteDeviationNotifications) {
                mapUtils.initBackgroundLocationGeoFencing();
            } else {
                mapUtils.stopBackgroundLocationGeoFencing();
            }
        }

        this.props.closeSettingsModal();
    };

    render() {
        return (
            <Modal isOpen={this.props.isSettingsModalOpen}
                style={config.DEFAULT_MODAL_STYLES}
                onRequestClose={this.props.closeToursModal}>
                <div className="default-modal settings-modal">
                    <header>
                        <h2 className="logged-in-user">
                            <img className="logged-in-user__photo" alt={this.state.user.displayName} src={this.state.user.photoURL} />
                            <span className="logged-in-user__name">{this.state.user.displayName}</span>
                        </h2>
                        <button className="default-modal__close"
                                type="button"
                                onClick={this.props.closeSettingsModal}>
                            <img alt="Close settings modal" src={closeButtonIcon} />
                        </button>
                    </header>
                    <div className="default-modal__row">
                        <input type="checkbox"
                               name="enable-notifications"
                               id="enable-notifications"
                               checked={this.state.settings.enableRouteDeviationNotifications}
                               onChange={this.toggleRouteDeviationNotifications} />
                        <label htmlFor="enable-notifications">Enable route deviation notifications</label>
                    </div>
                    <div className="default-modal__row close-to-next centered">
                        <button className="default-button full-button" type="button" onClick={this.signOut}>
                            Log out
                        </button>
                    </div>
                    <div className="default-modal__row centered">
                        <button className="default-modal__submit" type="button" onClick={this.saveSettings}>Save</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default SettingsModal;