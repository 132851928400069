import React, { Component } from 'react';

import config from './../../config';
import utils from './../../utils/utils';

import logo from './../../assets/logo.svg';
import './Login.css';

import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner';

let loginInitialized = false;

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userNotAllowed: false,
            loginText: 'Logging in...'
        };

        // Initialize login, but only once for the whole app session
        if (!loginInitialized) {
            loginInitialized = true;

            if (window.plugins && window.plugins.googleplus) {
                this.signInWithNativeGooglePlugin();
            } else {
                window.firebase.auth().getRedirectResult().then(this.onSignInResponse);
            }
        }
    }

    isUserAllowedToUseTheApp = async (retries = 3) => {
        try {
            await utils.authorizedAjax(config.API_ENDPOINT + '/users/is-authorized', 'get');
            return true;
        } catch (error) {
            console.log(error);
            if (error.status === 403) {
                return false;
            } else if (error.status === 401) {
                return 'NOT_LOGGED_IN';
            } else {
                if (retries > 0) {
                    return await this.isUserAllowedToUseTheApp(retries - 1);
                } else {
                    return 'SERVER_ERROR';
                }
            }
        }
    };

    signInWithGoogle = () => {
        if (window.plugins && window.plugins.googleplus) {
            this.signInWithNativeGooglePlugin();
        } else {
            const provider = new window.firebase.auth.GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            window.firebase.auth().signInWithRedirect(provider);
        }
    };

    signInWithNativeGooglePlugin = () => {
        window.plugins.googleplus.login(
            { 'webClientId': '1007490006208-vg688jqojgf8uu19utm0nvh23h3bn464.apps.googleusercontent.com' },
            result => {
              console.log(result);
              window.localStorage.setItem('google-auth-token', result.idToken);
              this.onSignInResponse({
                user: {
                    displayName: result.displayName,
                    photoURL: result.imageURL
                }
              });
            },
            () => {
                alert('Error while trying to log in.');
                this.signInWithNativeGooglePlugin();
            }
        );
    };

    onSignInResponse = async result => {
        if (result && result.user) {
            const isAuthorized = await this.isUserAllowedToUseTheApp(3);

            console.log('Is authorized:');
            console.log(isAuthorized);

            if (isAuthorized === 'NOT_LOGGED_IN') {
                return this.signInWithGoogle();
            }

            if (isAuthorized === 'SERVER_ERROR') {
                return this.setState({
                    userNotAllowed: true,
                    loginText: 'A server error occured while trying to log you in.'
                });
            }

            if (isAuthorized) {
                window.localStorage.setItem('user', JSON.stringify({
                    displayName: result.user.displayName,
                    photoURL: result.user.photoURL
                }));
                this.props.history.replace('/');
            } else {
                this.setState({
                    userNotAllowed: true,
                    loginText: 'You are not allowed to use this app.'
                });
            }
        } else {
            this.signInWithGoogle();
        }
    };

    render() {
        return (
            <div className="login-page">
                <div className="login-page__wrapper">
                    <img className="login-page__logo logo-transition" alt="Electric Tour" src={logo} />
                    <LoadingSpinner className={'login-page__loading' + (this.state.userNotAllowed ? ' hidden' : '')} />
                    <h1>{this.state.loginText}</h1>
                </div>
            </div>
        );
    }
}

export default Login;