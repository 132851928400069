import React, {Component} from 'react';
import Modal from 'react-modal';

import config from './../../config';
import utils from './../../utils/utils';

import closeButtonIcon from './../../assets/close.svg';

Modal.setAppElement('#root');

class UploadModal extends Component {
    getFormRef = elem => this.formRef = elem;

    submitForm = async () => {
        try {
            const formData = new FormData(this.formRef);
            const tourName = formData.get('tourName');

            this.props.closeUploadModal();
            
            if (!tourName) {
                alert('Tour name can\'t be empty.');
                return;
            }

            this.props.showLoading(true);

            let response;
            const existingTour = this.props.allTours.find(tour => tour.name.toLowerCase() === tourName.toLowerCase());
            if (existingTour) {
                const replaceExistingTour = window.confirm('Replace existing "' + existingTour.name + '" tour?');

                if (replaceExistingTour) {
                    formData.append('_id', existingTour._id);
                    response = await utils.authorizedAjax(config.API_ENDPOINT + '/tours', 'put', { data: formData });
                } else {
                    this.props.showLoading(false);
                    return;
                }
            } else {
                response = await utils.authorizedAjax(config.API_ENDPOINT + '/tours', 'post', { data: formData });
            }
            
            await this.props.getAllTours();
            this.props.showLoading(false);
            alert(
                'Successfully uploaded file.' +
                (response.data && response.data.warnings ? '\n' + response.data.warnings.join('\n') : '')
            );
        } catch (error) {
            this.props.showLoading(false);
            const message = error.response.data.message || 'An error occured while uploading the file.';
            alert(typeof message === 'string' ? message : message.join('\n'));
            utils.handleXhrErrors(error, this.props.history);
        }
    };

    render() {
        return (
            <Modal isOpen={this.props.isUploadModalOpen}
                style={config.DEFAULT_MODAL_STYLES}
                onRequestClose={this.props.closeUploadModal}>
                <form encType="multipart/form-data"
                    className="default-modal"
                    ref={this.getFormRef}>
                    <header>
                        <h2>Upload new tour</h2>
                        <button className="default-modal__close"
                                type="button"
                                onClick={this.props.closeUploadModal}>
                            <img alt="Close upload modal" src={closeButtonIcon} />
                        </button>
                    </header>
                    <h3>Select a JSON or CSV file with the tour coordinates</h3>
                    <div className="default-modal__row">
                        <input className="default-modal__file-input"
                            type="file"
                            name="geoJsonFile" />
                    </div>
                    <div className="default-modal__row">
                        <label htmlFor="uploaded-tour-name">Tour name:</label>
                        <input id="uploaded-tour-name" type="text" name="tourName" />
                    </div>
                    <div className="default-modal__row centered">
                        <button className="default-modal__submit" type="button" onClick={this.submitForm}>Upload Tour</button>
                    </div>
                </form>
            </Modal>
        );
    }
}

export default UploadModal;