import axios from 'axios';

import config from './../config';

let loadUserDataWaitTime = 0;

const checkIfUserDataIsLoaded = (resolve, reject) => {
    if (window.firebase.auth().currentUser || window.localStorage.getItem('google-auth-token')) {
        resolve(true);
    } else {
        if (loadUserDataWaitTime < config.LOAD_USER_DATA_TIMEOUT) {
            loadUserDataWaitTime += 200;
            setTimeout(checkIfUserDataIsLoaded.bind(null, resolve, reject), 200);
        } else {
            reject('Could not load user data.');
        }
    }
};

const utils = {

    isUserLoggedIn: () => utils.getLocalStorageObject('user'),

    signOut: () => {
        window.localStorage.removeItem('user');

        if (window.plugins && window.plugins.googleplus) {
            return new Promise(resolve => window.plugins.googleplus.logout(resolve));
        } else {
            return window.firebase.auth().signOut();
        }
    },

    onUserDataLoaded: () => {
        loadUserDataWaitTime = 0;
        return new Promise(checkIfUserDataIsLoaded)
    },

    getLocalStorageObject: (key) => {
        try {
            let value = window.localStorage.getItem(key);
            return value && JSON.parse(value);
        } catch (error) {
            console.error('The local storage entry is not an object.');
            return null;
        }
    },

    authorizedAjax: async (url, method, options = {}) => {
        try {
            await utils.onUserDataLoaded();
            const idToken = window.localStorage.getItem('google-auth-token') || await window.firebase.auth().currentUser.getIdToken(true);
            // console.log(idToken);
            if (!idToken) {
                throw new Error('UNAUTHORIZED');
            }

            const { headers, ...otherOptions } = options;

            return await axios.request({ // Await the response so in case there's an error, treat it using the catch block
                url,
                method,
                headers: {
                    'X-XSRF-TOKEN': idToken,
                    ...headers
                },
                ...otherOptions
            });
        } catch (error) {
            throw error;
        }
    },

    handleXhrErrors: (error, history) => {
        if (error.message === 'UNAUTHORIZED' || error.status === 401) {
            alert('Seems like you need to log in...');
            utils.signOut();
            history.replace('/login');
        }
    }
};

export default utils;