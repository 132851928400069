import React, { Component } from 'react';
import Modal from 'react-modal';

import config from './../../config';
import utils from './../../utils/utils';

import closeButtonIcon from './../../assets/close.svg';

Modal.setAppElement('#root');

class ToursModal extends Component{

    getToursListRef = elem => this.toursListRef = elem;

    selectTour = async (e, tourId) => {
        try {
            const selectedTour =  tourId || this.toursListRef.value;
            const response = await utils.authorizedAjax(config.API_ENDPOINT + '/tours/' + selectedTour, 'get');

            this.props.selectTour(selectedTour, response.data.points);
        } catch (error) {
            alert('An error occured while getting the data for the selected tour.');
            utils.handleXhrErrors(error, this.props.history);
        } finally {
            this.props.closeToursModal();
        }
    };
    
    render () {
        return (
            <Modal isOpen={this.props.isToursModalOpen}
                style={config.DEFAULT_MODAL_STYLES}
                onRequestClose={this.props.closeToursModal}>
                <div className="default-modal">
                    <header>
                        <h2>Select a tour</h2>
                        <button className="default-modal__close"
                                type="button"
                                onClick={this.props.closeToursModal}>
                            <img alt="Close upload modal" src={closeButtonIcon} />
                        </button>
                    </header>
                    <div className="default-modal__row">
                        <select name="selectedTour" ref={this.getToursListRef} defaultValue={this.props.selectedTour}>
                            {this.props.allTours.map((tour, index) => (
                                <option key={'tour-' + index}
                                        value={tour._id}>
                                    {tour.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="default-modal__row centered">
                        <button className="default-modal__submit" type="button" onClick={this.selectTour}>Select Tour</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ToursModal;