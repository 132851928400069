import React, { Component } from 'react';

import config from './../../config';
import utils from './../../utils/utils';
import mapUtils from './../../utils/map-utils';

import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner';
import EditTourPanel from './../../components/EditTourPanel/EditTourPanel';
import UploadModal from './../../components/UploadModal/UploadModal';
import ToursModal from './../../components/ToursModal/ToursModal';
import SettingsModal from './../../components/SettingsModal/SettingsModal';

import './App.css';
import routeIcon from './../../assets/route.svg';
import routeIconDark from './../../assets/route-dark.svg';
import importFileIcon from './../../assets/import-file.svg';
import importFileDarkIcon from './../../assets/import-file-dark.svg';
import googleMapsIcon from './../../assets/google-maps.svg';
import googleMapsDarkIcon from './../../assets/google-maps-dark.svg';
import settingsIcon from './../../assets/settings-icon.png';
import settingsDarkIcon from './../../assets/settings-icon-dark.png';
import clearIcon from './../../assets/close.svg';

const defaultMapPoints = [];

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      loadingSpinnerMessage: '',
      mapPoints: defaultMapPoints,
      googleMapsTourUrl: '',
      startCity: '',
      endCity: '',
      startCityIndex: -1,
      endCityIndex: -1,
      selectedSearchInput: null,
      searchResults: [],
      isEditMode: false,
      totalRouteDistance: 0,
      totalRouteDuration: 0,
      isToursModalOpen: false,
      isUploadModalOpen: false,
      isSettingsModalOpen: false,
      allTours: [],
      selectedTour: window.localStorage.getItem('selectedTour')
    };

    mapUtils.initMapUtils(this.setTourPoints, this.showLoading);
  }

  componentDidMount() {
    this.showLoading(true);
    this.loadMapScript();
    this.getAllTours();
  }

  showLoading = (isLoading, loadingSpinnerMessage = '') => this.setState({ isLoading, loadingSpinnerMessage });

  // Init logic

  getStartCityInputRef = elem => this.startCityInputRef = elem;
  getEndCityInputRef = elem => this.endCityInputRef = elem;

  loadMapScript = () => {
    const scriptTag = document.createElement('script');
    scriptTag.src = config.GOOGLE_API_SCRIPT_URL;
    scriptTag.async = true;
    document.head.appendChild(scriptTag);
  };

  getAllTours = async () => {
    try {
      const toursResponse = await utils.authorizedAjax(config.API_ENDPOINT + '/tours', 'get');
      const tours = toursResponse.data;
      this.setState({ allTours: tours }, this.loadSelectedTourOrFirstAvailable);
    } catch (error) {
      this.showLoading(false);
      utils.handleXhrErrors(error, this.props.history);
      alert('Could not load tours list.');
    }
  }

  loadSelectedTourOrFirstAvailable = async () => {
    if (this.state.allTours.length > 0) {
      try {
        // Select the saved tour or if none is saved, the latest one added in the DB
        const selectedTour = this.state.allTours.find(tour => tour._id === this.state.selectedTour) ?
          this.state.selectedTour : this.state.allTours[this.state.allTours.length - 1]._id;
        const response = await utils.authorizedAjax(config.API_ENDPOINT + '/tours/' + selectedTour, 'get');
        this.selectTour(selectedTour, response.data.points);
      } catch (error) {
        this.showLoading(false);
        utils.handleXhrErrors(error, this.props.history);
        alert('An error occured while loading the selected or default tour.');
      }
    }
  };


  // Select tour logic

  openToursModal = () => this.setState({ isToursModalOpen: true });
  closeToursModal = () => this.setState({ isToursModalOpen: false });

  selectTour = (tourId, points) => {
    window.localStorage.setItem('selectedTour', tourId);
    const tourPoints = points || defaultMapPoints;
    this.setState({
      selectedTour: tourId,
      mapPoints: tourPoints,
      startCityIndex: 0,
      startCity: tourPoints[0]['Description']
    }, this.setRoute);
  }


  // Edit tour logic

  toggleEditMode = (resetRoute = true) => {
    const isEditMode = !this.state.isEditMode
    this.setState({ isEditMode: isEditMode }, resetRoute ? this.setRoute.bind(this, false) : null);
  };

  setTourPoints = points => this.setState({ mapPoints: points }, this.setRoute);


  // Search logic

  searchCity = e => {
    const input = e.target;
    const city = input.value;
    const isStartCity = input === this.startCityInputRef;

    if (isStartCity) {
      this.setState({ startCity: city, selectedSearchInput: 'startCity' });
    } else {
      this.setState({ endCity: city, selectedSearchInput: 'endCity' });
    }

    if (!city) {
      if (isStartCity) {
        this.clearStartCity();
      } else {
        this.clearEndCity();
      }
      return;
    }

    if (!isNaN(parseInt(city, 10))) {
      this.setState({
        searchResults: this.state.mapPoints
          .filter(point => point['ID'].toString().indexOf(city) > -1)
          .slice(0, 10)
      });
    } else {
      this.setState({
        searchResults: this.state.mapPoints
          .filter(point => point['Description'].toLowerCase().indexOf(city.trim().toLowerCase()) > -1)
          .slice(0, 10)
      });
    }
  };

  clearStartCity = () => {
    this.setState({
      startCity: '',
      startCityIndex: -1,
      searchResults: [],
      totalRouteDistance: 0,
      totalRouteDuration: 0,
      googleMapsTourUrl: ''
    });

    mapUtils.clearRoute();
  };

  clearEndCity = () => {
    this.setState({
      endCity: '',
      endCityIndex: -1,
      searchResults: [],
      totalRouteDistance: 0,
      totalRouteDuration: 0,
      googleMapsTourUrl: ''
    }, () => {
      mapUtils.clearRoute();

      if (this.state.startCityIndex > -1) {
        this.setRoute();
      }
    });
  };

  selectCity = e => {
    const cityData = e.target.dataset;
    const cityIndex = this.state.mapPoints.findIndex(
      point => parseInt(point['ID'], 10) === parseInt(cityData.id, 10)
    );

    this.blurInputs();

    if (this.state.selectedSearchInput === 'startCity') {
      if (this.state.endCityIndex > -1 && this.state.endCityIndex < cityIndex) {
        this.clearStartCity();
        alert('Please select a city that is before the end city in the itinerary.');
        return;
      }
      this.setState({
        startCityIndex: cityIndex,
        startCity: this.state.mapPoints[cityIndex]['Description']
      }, this.setRoute);
    } else {
      if (this.state.startCityIndex > -1 && this.state.startCityIndex > cityIndex) {
        this.clearEndCity();
        alert('Please select a city that is after the start city in the itinerary.');
        return;
      }

      this.setState({
        endCityIndex: cityIndex,
        endCity: this.state.mapPoints[cityIndex]['Description']
      }, () => {
        if (this.state.startCityIndex > -1) {
          this.setRoute();
        }
      });
    }
  };

  blurInputs = () => {
    this.setState({ searchResults: [] });
    this.startCityInputRef.blur();
    this.endCityInputRef.blur();
  };


  // Max waypoints logic

  setRoute = async (noLoading) => {
    if (!noLoading) {
      this.showLoading(true);
    }
    const startPointIndex = this.state.startCityIndex;
    const endPointIndex = this.state.endCityIndex > -1 ? this.state.endCityIndex : this.state.mapPoints.length - 1;

    // Clear previous routes (it might be needed in case you only delete
    // some letters from a search query and then select another city).
    mapUtils.clearRoute();

    // Draw route on map
    const totalRouteInfo = await mapUtils.drawRouteOnMap(this.state.mapPoints, this.state.isEditMode, startPointIndex, endPointIndex);

    // The Google Maps app supports only 9 waypoints, thus the endpoint index should not exceed 10
    const maxMapsAppEndIndex = endPointIndex > startPointIndex + config.MAX_MAPS_APP_WAYPOINTS + 1 ?
      startPointIndex + config.MAX_MAPS_APP_WAYPOINTS + 1 : endPointIndex;
    this.setState({
      ...totalRouteInfo,
      googleMapsTourUrl: mapUtils.buildGoogleMapsTourUrl(this.state.mapPoints, startPointIndex, maxMapsAppEndIndex),
      searchResults: [],
      isLoading: false
    });
  };


  // Open upload modal

  openUploadModal = () => this.setState({ isUploadModalOpen: true });
  closeUploadModal = () => this.setState({ isUploadModalOpen: false });


  // Settings modal

  openSettingsModal = () => this.setState({ isSettingsModalOpen: true });
  closeSettingsModal = () => this.setState({ isSettingsModalOpen: false });


  // HTML rendering

  render() {
    return ([
      <div className="map-tour" key="map-tour">
        <div className="map-tour__search-bar">
          <div className="map-tour__search-bar__column">
            <input className={`map-tour__search-bar__input ${this.state.isEditMode ? 'disabled' : ''}`}
              ref={this.getStartCityInputRef}
              type="text"
              autoComplete="off"
              name="start_point"
              placeholder="Start location"
              value={this.state.startCity}
              onChange={this.searchCity} />
            {this.state.startCity !== '' && !this.state.isEditMode ? (
              <button className="map-tour__search-bar__clear-button"
                type="button"
                onClick={this.clearStartCity}>
                <img alt="Clear search" src={clearIcon} />
              </button>
            ) : null}
          </div>
          <div className="map-tour__search-bar__column">
            <input className={`map-tour__search-bar__input ${this.state.isEditMode ? 'disabled' : ''}`}
              ref={this.getEndCityInputRef}
              type="text"
              autoComplete="off"
              name="start_point"
              placeholder="Destination"
              value={this.state.endCity}
              onChange={this.searchCity} />
            {this.state.endCity !== '' && !this.state.isEditMode ? (
              <button className="map-tour__search-bar__clear-button"
                type="button"
                onClick={this.clearEndCity}>
                <img alt="Clear search" src={clearIcon} />
              </button>
            ) : null}
          </div>
          {this.state.searchResults.length ?
            (
              <ul className="map-tour__search-bar__results" onClick={this.selectCity}>
                {this.state.searchResults.map((elem, i) => (
                  <li key={'city-' + i + '-' + elem['ID']}
                    data-id={elem['ID']}
                    data-name={elem['Description']}
                    className={i % 2 === 0 ? 'list-item-dark' : ''}>
                    <i>#{elem['ID']}</i> {elem['Description']}
                  </li>
                ))}
              </ul>
            ) : null
          }
        </div>
        {this.state.startCityIndex > -1 && config.IS_WEB_BUILD ?
          <EditTourPanel history={this.props.history}
            isEditMode={this.state.isEditMode}
            showLoading={this.showLoading}
            toggleEditMode={this.toggleEditMode}
            tourPoints={this.state.mapPoints}
            allTours={this.state.allTours}
            selectedTour={this.state.selectedTour}
            startCityIndex={this.state.startCityIndex}
            endCityIndex={
              this.state.endCityIndex > -1 ? this.state.endCityIndex : this.state.mapPoints.length - 1
            } />
          : null
        }
        {this.state.totalRouteDistance || this.state.totalRouteDuration ? (
          <div className="map-tour__route-bar">
            {
              this.state.totalRouteDistance ? (
                <span className="map-tour__route-bar__total-distance">
                  Total: <strong>{(this.state.totalRouteDistance / 1000).toFixed(2)} km</strong>
                </span>
              ) : null
            }
            {
              this.state.totalRouteDuration ? (
                <strong className="map-tour__route-bar__total-duration">(
                  {
                    parseInt(this.state.totalRouteDuration / 3600, 10) + 'h ' +
                    parseInt(this.state.totalRouteDuration / 60 % 60, 10) + 'min'
                  }
                  )</strong>
              ) : null
            }
          </div>
        ) : null}
        <div className="map-tour__google-map" id="google-map" onClick={this.blurInputs}></div>
        <div className="map-tour__actions-bar" onMouseDown={this.blurInputs} onTouchStart={this.blurInputs}>
          <button type="button"
            className={`map-tour__actions-bar__button select-tour ${this.state.isEditMode ? 'disabled' : ''}`}
            onClick={this.state.isEditMode ? null : this.openToursModal}>
            <img alt="Select tour" src={this.state.isEditMode ? routeIconDark : routeIcon} />
            <span>Select tour</span>
          </button>
          {config.IS_WEB_BUILD ? (
            <button type="button"
              className={
                `map-tour__actions-bar__button import-coords-file-button ${this.state.isEditMode ? 'disabled' : ''}`
              }
              onClick={this.state.isEditMode ? null : this.openUploadModal}>
              <img alt="Import coordinates file" src={this.state.isEditMode ? importFileDarkIcon : importFileIcon} />
              <span>Import coordinates file</span>
            </button>
          ) : null}
          <a className={
            `map-tour__actions-bar__button open-google-maps-button ${this.state.googleMapsTourUrl ? '' : 'disabled'}`
          }
            target="_blank"
            rel="noopener noreferrer"
            href={this.state.googleMapsTourUrl}>
            <img alt="Open in Google Maps" src={this.state.googleMapsTourUrl ? googleMapsIcon : googleMapsDarkIcon} />
            <span>Open in Google maps</span>
          </a>
          <button className={`map-tour__actions-bar__button settings-button ${this.state.isEditMode ? 'disabled' : ''}`}
            onClick={this.state.isEditMode ? null : this.openSettingsModal}>
            <img alt="Settings" src={this.state.isEditMode ? settingsDarkIcon : settingsIcon} />
          </button>
        </div>
      </div>,

      <div key="loading-spinner" className={`map-tour__loading-spinner ${this.state.isLoading ? '' : 'hidden'}`}>
        <LoadingSpinner message={this.state.loadingSpinnerMessage} />
      </div>,

      <ToursModal key="tours-modal"
        history={this.props.history}
        allTours={this.state.allTours}
        selectedTour={this.state.selectedTour}
        selectTour={this.selectTour}
        isToursModalOpen={this.state.isToursModalOpen}
        closeToursModal={this.closeToursModal} />,

      <UploadModal key="upload-modal"
        showLoading={this.showLoading}
        history={this.props.history}
        allTours={this.state.allTours}
        getAllTours={this.getAllTours}
        isUploadModalOpen={this.state.isUploadModalOpen}
        closeUploadModal={this.closeUploadModal} />,

      <SettingsModal key="settings-modal"
        history={this.props.history}
        isSettingsModalOpen={this.state.isSettingsModalOpen}
        closeSettingsModal={this.closeSettingsModal} />
    ]);
  }
}

export default App;