import React from 'react';
import Modal from 'react-modal';

import config from './../../config';

import closeButtonIcon from './../../assets/close.svg';

const SaveTourModal = props => (
    <Modal isOpen={props.isSaveTourModalOpen}
           style={config.DEFAULT_MODAL_STYLES}
           onRequestClose={props.closeModal}>
        <div className="default-modal save-tour-modal">
            <header>
                <h2>Save tour changes?</h2>
                <button className="default-modal__close"
                        type="button"
                        onClick={props.closeModal}>
                    <img alt="Close settings modal" src={closeButtonIcon} />
                </button>
            </header>
            <div className="default-modal__row centered">
                <button className="default-button"
                        style={{marginRight: '10px', width: '270px'}}
                        type="button"
                        onClick={props.discardChanges}>
                    Discard changes
                </button>
                <button className="default-modal__submit" type="button" onClick={props.saveTourChanges}>
                    Save
                </button>
            </div>
        </div>
    </Modal>
);

export default SaveTourModal;