import React, { Component } from 'react';

import config from './../../config';
import utils from './../../utils/utils';
import mapUtils from './../../utils/map-utils';

import AddMarkerModal from './../AddMarkerModal/AddMarkerModal';
import SaveTourModal from './../SaveTourModal/SaveTourModal';

import './EditTourPanel.css';

import editTourIcon from './../../assets/edit-tour.svg';
import exitEditTourIcon from './../../assets/close-white.svg';
import addMarkerIcon from './../../assets/add-marker.svg';
import undoIcon from './../../assets/undo-arrow.svg';
import redoIcon from './../../assets/redo-arrow.svg';

class EditTourPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAddMarkerModalOpen: false,
            isSaveTourModalOpen: false,
            isUndoDisabled: mapUtils.isStartOfHistory(),
            isRedoDisabled: mapUtils.isEndOfHistory()
        };
    }

    static getDerivedStateFromProps(newProps) {
        if (newProps.isEditMode) {
            return {
                isUndoDisabled: mapUtils.isStartOfHistory(),
                isRedoDisabled: mapUtils.isEndOfHistory()
            };
        }

        return null;
    }

    toggleEditMode = () => {
        if (this.props.isEditMode && mapUtils.wereChangesMade()) {
            this.openSaveTourModal();
        } else {
            mapUtils.initEditHistory(this.props.tourPoints); // Clear previous edit history and initialize it with current state
            this.props.toggleEditMode();
        }
    };

    openAddMarkerModal = () => this.setState({ isAddMarkerModalOpen: true });
    closeAddMarkerModal = () => this.setState({ isAddMarkerModalOpen: false });

    enterAddMarkerMode = markerOrderNumber => {
        const minOrderNumber = this.props.startCityIndex + 1;
        const maxOrderNumber = this.props.endCityIndex + 2;
        if (markerOrderNumber >= minOrderNumber && markerOrderNumber <= maxOrderNumber) {
            mapUtils.setAddMarkerMode(true, markerOrderNumber);
            this.closeAddMarkerModal();
        } else {
            alert(`You need to specify an order number between ${minOrderNumber} and ${maxOrderNumber}`);
        }
    };

    openSaveTourModal = () => this.setState({ isSaveTourModalOpen: true });
    closeSaveTourModal = () => this.setState({ isSaveTourModalOpen: false });

    saveTourChanges = async () => {
        const selectedTour = this.props.allTours.find(tour => tour._id === this.props.selectedTour);

        if (selectedTour) {
            try {
                this.closeSaveTourModal();
                this.props.showLoading(true);

                const response = await utils.authorizedAjax(config.API_ENDPOINT + '/tours/' + selectedTour._id, 'put', {
                    data: {
                        name: selectedTour.name,
                        points: this.props.tourPoints
                    }
                });

                this.props.toggleEditMode();
                mapUtils.clearHistory();
                this.props.showLoading(false);

                if (response.data && response.data.warnings) {
                    alert('Saved tour with warnings:\n' + response.data.warnings.join('\n'));
                }
            } catch (error) {
                this.discardTourChanges();
                this.props.showLoading(false);
                const message = error.response.data.message || 'An error occurred while trying to save the tour.';
                alert(typeof message === 'string' ? message : message.join('\n'));
                utils.handleXhrErrors(error, this.props.history);
            }
        } else {
            alert('An error occurred while trying to save the tour.');
        }
    };

    discardTourChanges = () => {
        this.closeSaveTourModal();
        this.props.toggleEditMode(false);
        mapUtils.discardTourChanges();
    };

    render() {
        return (
            <div className="edit-tour-panel">
                <button type="button"
                        className={`edit-tour-panel__button edit-tour ${this.props.isEditMode ? 'edit-mode' : ''}`}
                        onClick={this.toggleEditMode}>
                    <img alt={ this.props.isEditMode ? 'Edit Mode OFF' : 'Edit Mode ON' }
                         src={ this.props.isEditMode ? exitEditTourIcon : editTourIcon } />
                </button>
                {this.props.isEditMode && [
                    <button key="add-marker-button"
                            type="button"
                            className="edit-tour-panel__button add-marker"
                            onClick={this.openAddMarkerModal}>
                        <img alt="Add marker" src={ addMarkerIcon } />
                    </button>,
                    <button key="undo-button"
                            type="button"
                            className={
                                `edit-tour-panel__button undo-change ${ this.state.isUndoDisabled ? 'disabled' : '' }`
                            }
                            onClick={mapUtils.undoEdit}>
                        <img alt="Undo" src={ undoIcon } />
                    </button>,
                    <button key="redo-button"
                            type="button"
                            className={
                                `edit-tour-panel__button redo-change ${ this.state.isRedoDisabled ? 'disabled' : '' }`
                            }
                            onClick={mapUtils.redoEdit}>
                        <img alt="Redo" src={ redoIcon } />
                    </button>,
                    <AddMarkerModal key="add-marker-modal"
                                    isAddMarkerModalOpen={this.state.isAddMarkerModalOpen}
                                    closeAddMarkerModal={this.closeAddMarkerModal}
                                    minOrderNumber={this.props.startCityIndex + 1}
                                    maxOrderNumber={this.props.endCityIndex + 1}
                                    enterAddMarkerMode={this.enterAddMarkerMode} />,
                    <SaveTourModal key="save-tour-modal"
                                   isSaveTourModalOpen={this.state.isSaveTourModalOpen}
                                   saveTourChanges={this.saveTourChanges}
                                   discardChanges={this.discardTourChanges}
                                   closeModal={this.closeSaveTourModal} />
                ]}
            </div>
        );
    }
}

export default EditTourPanel;