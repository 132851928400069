import React from 'react';
import Modal from 'react-modal';

import config from './../../config';

import closeButtonIcon from './../../assets/close.svg';

const AddMarkerModal = props => {
    const markerOrderRef = React.createRef();
    const enterAddMarkerMode = () => props.enterAddMarkerMode(markerOrderRef.current.value);

    return (
        <Modal isOpen={props.isAddMarkerModalOpen}
               style={config.DEFAULT_MODAL_STYLES}
               onRequestClose={props.closeAddMarkerModal}>
            <div className="default-modal add-marker-modal">
                <header>
                    <h2>Add marker</h2>
                    <button className="default-modal__close"
                            type="button"
                            onClick={props.closeAddMarkerModal}>
                        <img alt="Close settings modal" src={closeButtonIcon} />
                    </button>
                </header>
                <div className="default-modal__row">
                    <label htmlFor="">Order number:</label>
                    <input id="marker-order-number"
                           ref={markerOrderRef}
                           name="marker-order-number"
                           type="number"
                           min={props.minOrderNumber}
                           max={props.maxOrderNumber} />
                </div>
                <div className="default-modal__row centered">
                    <button className="default-modal__submit" type="button" onClick={enterAddMarkerMode}>
                        Add marker
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default AddMarkerModal;