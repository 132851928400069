const config = {};

config.IS_DEV = false;
config.IS_WEB_BUILD = true;

config.PORT = 3200;

config.API_ENDPOINT = config.IS_DEV ?
  `http://localhost:${config.PORT}` : `https://wenwere.com/api`;

config.FIREBASE = {
    apiKey: "AIzaSyBPkUPZXkFAngq4Rx-bdo79q2cbwCMZfcY",
    authDomain: "wenwere-fcm.firebaseapp.com",
    databaseURL: "https://wenwere-fcm.firebaseio.com",
    projectId: "wenwere-fcm",
    storageBucket: "wenwere-fcm.appspot.com",
    messagingSenderId: "1007490006208"
};

config.GOOGLE_MAPS_API_KEY = 'AIzaSyAZyGr_Np_CJu5g1oQTxHK0TlitmvGOqpk';
config.GOOGLE_API_SCRIPT_URL = `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_MAPS_API_KEY}&callback=initMap`;
config.OPEN_MAPS_APP_BASE_URL = 'https://www.google.com/maps/dir/?api=1';
config.GOOGLE_MAPS_SETTINGS = {
    zoom: 10,
    center: {
        lat: 45.7564,
        lng: 21.2297
    },
    mapTypeControl: false
};
config.ROUTE_DEVIATION_TOLERANCE = 0.003;
config.MAX_WAYPOINTS = 23; // Waypoints, except the start point and end point
config.MAX_MAPS_APP_WAYPOINTS = 9;

config.DEFAULT_MODAL_STYLES = {
    overlay: {
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    },
    content: {
        position: 'static'
    }
};

config.LOAD_USER_DATA_TIMEOUT = 5000;

export default config;